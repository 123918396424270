import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import { createPinia } from '@pinia';
// import { ClickOutsideDirectivePlugin } from '@core/directives/vClickOutside';
import { clickOutside } from '@core/directives/сlickOutside';
import notify from '@core/plugins/notify'
import dayjs from '@core/plugins/dayjs';

import "@core/assets/style/main.scss";

const app = createApp(App);
const pinia = createPinia();

app.use(pinia);
app.use(router);
app.use(notify);
app.use(dayjs);
app.use(clickOutside);
app.mount('#app');